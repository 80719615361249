(function () {

  'use strict';

  /* jQuery */
  global.$ = global.jQuery = require('jquery');

  require('slick');
  require('dzTabs');
  require('floatcolumns');

  var viewportWidth;
  var breakpoint;
  var bpLevel;

  var bpInter = 480;
  var bpLevelInter = 1;
  var bpMedium = 720;
  var bpLevelMedium = 2;
  var bpLarge = 980;
  var bpLevelLarge = 3;
  var bpXlarge = 1100;
  var bpLevelXlarge = 4;
  var bpXXlarge = 1200;
  var bpLevelXXlarge = 5;

  var eventsActive = false;


  /* Foundation */
  //require('what-input');
  //require('foundation-sites');   use this to include all of foundation js

  //require('foundation-sites/dist/foundation');

  /*
  require('foundation-sites/js/foundation.core');
  require('foundation-sites/js/foundation.util.mediaQuery');
  require('foundation-sites/js/foundation.util.keyboard');
  require('foundation-sites/js/foundation.util.box');
  require('foundation-sites/js/foundation.util.nest');
  require('foundation-sites/js/foundation.util.motion');
  require('foundation-sites/js/foundation.util.timerAndImageLoader');

  require('foundation-sites/js/foundation.responsiveToggle');
  require('foundation-sites/js/foundation.responsiveMenu');
  require('foundation-sites/js/foundation.dropdownMenu');
  require('foundation-sites/js/foundation.drilldown');

  require('foundation-sites/js/foundation.equalizer');
  */


  require('foundation-sites/js/foundation.core');
  //require('foundation-sites/js/foundation.abide');
  //require('foundation-sites/js/foundation.accordion');
  //require('foundation-sites/js/foundation.accordionMenu');
  require('foundation-sites/js/foundation.drilldown');
  //require('foundation-sites/js/foundation.dropdown');
  //require('foundation-sites/js/foundation.dropdownMenu');
  require('foundation-sites/js/foundation.equalizer');
  //require('foundation-sites/js/foundation.interchange');
  //require('foundation-sites/js/foundation.magellan');
  require('foundation-sites/js/foundation.offcanvas');
  //require('foundation-sites/js/foundation.orbit');
  require('foundation-sites/js/foundation.responsiveMenu');
  require('foundation-sites/js/foundation.responsiveToggle');
  //require('foundation-sites/js/foundation.reveal');
  //require('foundation-sites/js/foundation.slider');
  require('foundation-sites/js/foundation.sticky');
  require('foundation-sites/js/foundation.tabs');
  require('foundation-sites/js/foundation.toggler');
  //require('foundation-sites/js/foundation.tooltip');

  require('foundation-sites/js/foundation.util.box');
  require('foundation-sites/js/foundation.util.keyboard');
  require('foundation-sites/js/foundation.util.mediaQuery');
  require('foundation-sites/js/foundation.util.motion');
  require('foundation-sites/js/foundation.util.nest');
  require('foundation-sites/js/foundation.util.timerAndImageLoader');
  require('foundation-sites/js/foundation.util.touch');
  require('foundation-sites/js/foundation.util.triggers');



  // INIT
  var development = $('body').hasClass('development');

  $( document ).ready(function() {
    /* initialize Foundation */
    $(document).foundation();
  });

  $(window).on('load', function () {

    updateBreakpoint();

    initSliders();
    initNavDropdown();
    resizeNavMain();
    initFloatColumns();

    $(document).generateTabs();
    Foundation.reInit('equalizer');

    initDev();


  });


  // ON RESIZE

  $(window).on('resize', function () {
    updateBreakpoint();
    resizeNavMain();
    //Foundation.reInit('equalizer');
  });




  function updateBreakpoint() {
    viewportWidth = window.innerWidth;
    $('#screenwidth').html('viewport-width: ' + viewportWidth);
    //console.log(viewportWidth);

    if (viewportWidth < bpInter) {
      breakpoint = 'small';
      bpLevel = 0;
    } else if (viewportWidth < bpMedium) {
      breakpoint = 'inter';
      bpLevel = 1;
    } else if (viewportWidth < bpLarge) {
      breakpoint = 'medium';
      bpLevel = 2;
    } else if (viewportWidth < bpXlarge) {
      breakpoint = 'large';
      bpLevel = 3;
    } else if (viewportWidth < bpXXlarge) {
      breakpoint = 'xlarge';
      bpLevel = 4;
    } else {
      breakpoint = 'xxlarge';
      bpLevel = 5;
    }
    $('body').removeClass('small inter medium large xlarge xxlarge');
    $('body').addClass(breakpoint);

    //console.log(breakpoint);
  }


  function initSliders() {

    $('.mod_article.slider:not(.slider_adapt) > div.article_inside').each(function() {
      //var el = $(this).children().prop("tagName").toLowerCase();
      //alert(el);
      $(this).slick({
        slide: 'section, div',
        dots: true,
        //arrows: false,
        adaptiveHeight: false
      });
    });

    $('.mod_article.slider.slider_adapt > div.article_inside').each(function() {
      //var el = $(this).children().prop("tagName").toLowerCase();
      //alert(el);
      $(this).slick({
        slide: 'section, div',
        dots: true,
        adaptiveHeight: true
      });
    });

  }


  function initDev() {

    $('#toggle-label').click(function() {
      $('body').toggleClass('dev-show-label');
    });

  }

  function initNavDropdown() {

    $('.dropdown-nav-select').on('change', function() {
      var href = $(this).val();
      if (href != '-') {
        window.location.href = href;
      }
    });

  }

  function initFloatColumns() {
    $('.float-columns.twitter').floatColumns({'maxcols': 3});
  }

  function resizeNavMain() {

    //$('.main-nav .top-bar').css('min-height', window.innerHeight);
    $('.main-nav .top-bar').css('min-height', $(document).height());

  }

}());


