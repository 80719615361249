;
(function ($) {

  $.fn.generateTabs = function (options) {

    var output = '';
    var numTabElements = $(".ce_dztabs").size();

    if (numTabElements == 0) {
      return;
    }

    var numTabs = 1;
    var numTabContainer = 1;
    var liTabs = Array();
    var baseHref = window.location.protocol + "//" + window.location.hostname + window.location.pathname;
    //var baseHref = '';

    $(".ce_dztabs").each(function () {

      var tabElement = $(this);
      var isActive = false;

      tabElement.addClass('dz_tab_' + numTabContainer);
      if (numTabs == 1) {
        isActive = true;
        tabElement.addClass('is-active');
      }
      tabElement.attr('id', 'dz_tab_' + numTabContainer + '_' + numTabs);

      if (numTabs == 1) {
        liTabs[numTabContainer] = '';
      }

      liTabs[numTabContainer] += '<li class="tabs-title tab-pos-' + numTabs + ' ' + (isActive ? 'is-active' : '') + '"><a href="'+baseHref+'#dz_tab_' + numTabContainer + '_' + numTabs + '" ' + (isActive ? 'aria-selected="true"' : '') + '>' + tabElement.find('h2').html() + '</a></li>';
      numTabs++;

      if (!tabElement.next().hasClass('ce_dztabs')) {
        liTabs[numTabContainer] = '<ul class="tabs" data-tabs id="tab-header-'+numTabContainer+'">' + liTabs[numTabContainer] + '</ul>';
        //alert(liTabs[numTabContainer]);
        numTabContainer++;
        numTabs = 1;
      }

    });

    for (var i = 1; i < numTabContainer; i++) {
      //debug.log(i);
      var tabContainerId = "tab-container-" + i;

      $(".dz_tab_" + i).wrapAll('<div id="' + tabContainerId + '" class="ce_dz_tabcontainer" />');
      $(".dz_tab_" + i).wrapAll('<div class="tabs-content" data-tabs-content="tab-header-'+i+'" />');


      $("#"+tabContainerId).prepend(liTabs[i]);
      var tabHeadline = $("#"+tabContainerId).find('.ce_dztabs > h4').html();

      $("#"+tabContainerId).find('.ce_dztabs > h2').each(function () {
        var tabHeadline = $(this).html();
        $(this).replaceWith('<h2 class="hideme">' + tabHeadline + '</h2>');
      });

      //Foundation.reInit($('[data-tabs]'));

      var tabs = new Foundation.Tabs($('[data-tabs]'));

      $("#"+tabContainerId).on('change.zf.tabs', function() {
        Foundation.reInit('equalizer');
      });
      
      /*
       $("#"+tabContainerId).tabs({
       activate: function( event, ui ) {
       //$('.equalize').equalize();
       }
       });
       */

    }

  };

}(jQuery));

