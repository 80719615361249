;(function($, window, document, undefined) {
 
  $.fn.floatColumns = function(options) {
   
    this.each(function() {
      
      var config = {};
      this.options = $.extend(config, options);

      var ext = $(this);
      
      var numColumns = 1;
      var numElem;
    
     
      
      this.init = function() {
        var _self = this;

        if (ext.find('> .article_inside').length) {
          ext.find('> .article_inside > div').addClass('fc-elem');
        } else {
          ext.find('> div').addClass('fc-elem');
        }

        numElem =  ext.find('.fc-elem').length;

        ext.find('.fc-elem').each(function() {
          $(this).addClass('fc-elem-'+$(this).index());
        });
        
        ext.prepend('<div class="fc-cols data-equalizer"></div>');
      
        this.calcNumColumns();
        
        this.addFloatColumns();
      
        this.distributeFloatElements();      

        $(window).bind('resize', function() { _self.redistributeFloatColumns(); } );        
        
      }


      this.calcNumColumns = function() {
      
        var windowWidth = window.innerWidth;
        
        var oldNumColumns = numColumns;

        if (this.options.maxcols == 3) {

          if (windowWidth > 960) {
            numColumns = 3;
          } else if (windowWidth > 640) {
            numColumns = 2;
          } else {
            numColumns = 1;
          }

        } else {

          if (windowWidth > 1200) {
            numColumns = 5;
          } else if (windowWidth > 960) {
            numColumns = 3;
          } else if (windowWidth > 640) {
            numColumns = 3;
          } else if (windowWidth > 580) {
            numColumns = 2;
          } else {
            numColumns = 1;
          }
        }


        return (oldNumColumns == numColumns);
        
      }
    
      
      this.addFloatColumns = function() {
        ext.find('.fc-cols').children().remove();
        for (var i=1; i<=numColumns; i++) {
          ext.find('.fc-cols').append('<div class="fc-col fc-col-'+numColumns+'" data-equalizer-watch></div>');
        }
      }
      
      this.distributeFloatElements = function() {
      
        for (var i=0; i<numElem; i++) {
          var posCol = (i % numColumns);
          ext.find('.fc-elem').eq(i).appendTo( ext.find('.fc-col-'+numColumns+':eq('+posCol+')'));
        }
        this.resizeColumns();
      
      }
      
      this.redistributeFloatColumns = function() {
        
        if (this.calcNumColumns()) { return; }
      
        for (var i=0; i<numElem; i++) {
          ext.find('.fc-elem-'+i).appendTo(ext);
        }
        
        this.addFloatColumns();
        this.distributeFloatElements();
        
      }

      this.resizeColumns = function() {
        var columnContainerHeight =  ext.find('.fc-cols').height();
        ext.find('.fc-col').css('height', columnContainerHeight);
      }
      
        
      this.init();
      
    });
    
 
    return this;
 
  };
 
  
})(jQuery, window, document);

